import {
  FILTER_SCAN,
  FETCH_SCANS_REQUEST,
  FETCH_SCANS_SUCCESS,
  TRIGGER_NEW_SCAN_REQUEST,
  TRIGGER_NEW_SCAN_SUCCESS,
} from "action_types/scan_table";

import { API, APIv2, API_Payment } from "utils/api";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import { showSuccessModal, hideIntegrationDetailsModal, hideSkipInventoryModal } from "actions/modals";
import { errorHandler } from "utils/error_handler";
import { fetchRhSecurityRisksDetails } from "actions/issue_table";
import { fetchReqDomain } from "actions/onboarding";
import { fetchTargets } from "actions/client_settings";
import {
  fetchDashboardStatus
} from 'actions/dashboard';
import { cloudflareErrorData, gcpErrorData, doErrorData } from "./error_data";
// import * as errorTypes from 'action_types/error';
import updateUrlAndFetchData from "utils/update_data_url";
import { fetchOrgsList } from 'actions/onboarding';

export const triggerNewScan = (scanDetails, history) => async (dispatch) => {
  try {
    dispatch({ type: TRIGGER_NEW_SCAN_REQUEST });
    const requestObject = {
      target: scanDetails.assets,
      scan_name: toLower(scanDetails.scanName),
      schedule_data: scanDetails.scheduleData,
      aws_keys_data: scanDetails.awsKeys,
      notify_medium: scanDetails.notifyMedium,
    };

    const { status } = await API.post(
      `/scans/?type=${scanDetails.scanType}`,
      requestObject
    );

    if (status === 201) {
      dispatch({ type: TRIGGER_NEW_SCAN_SUCCESS });
      const redirectTo = scanDetails.onDemandScan
        ? "/scans"
        : "/scheduled-scans";
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "New Scan Started!",
          message: "The new scan was triggered successfully.",
          redirectTo,
          buttonTitle: "Scans List",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const triggerRunScan = (type, orgId, history) => async (dispatch) => {
  try {
    dispatch({ type: TRIGGER_NEW_SCAN_REQUEST });

    const { status } = await API.post(
      `org/${orgId}/trigger-scan/?scan_type=${type}`,
      {}
    );
    if (status === 201 || 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: `${type} Scan Started!`,
          message: `The ${type} scan was triggered successfully.`,
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
    dispatch(fetchScansOverview(history, orgId));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const triggerFirstScan = (type, orgId, history) => async (dispatch) => {
  try {
    dispatch({ type: TRIGGER_NEW_SCAN_REQUEST });

    const { status } = await API.post(
      `org/${orgId}/trigger-scan/?scan_type=${type}&first_scan=true`,
      {}
    );
    if (status === 201 || 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: `First Scan Started!`,
          message: `The First scan was triggered successfully.`,
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
    dispatch(fetchTargets(history));
    dispatch(fetchDashboardStatus(history));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const markFinishedScan = (type, job_id, history) => async (dispatch) => {
  try {
    dispatch({ type: TRIGGER_NEW_SCAN_REQUEST });

    const { status } = await API.post(
      `/mark-finished/`,
      { job_id: job_id }
    );
    if (status === 201 || 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: `${type} Scan Finished!`,
          message: `The ${type} scan was marked as finished successfully.`,
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
    dispatch(fetchScansOverview(history, orgId));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteScan = (
  scanInfo,
  redirectTo,
  history,
  deleteScheduledScan
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "SHOW_MODAL_LOADER",
    });
    const requestObject = {
      scan_id: scanInfo.scanId,
    };

    const apiUrl = deleteScheduledScan
      ? `scans/schedule/${scanInfo.scanId}/delete/`
      : `/scans/${scanInfo.scanId}/delete/`;

    const { status } = await API.post(apiUrl, requestObject);

    if (status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Scan Deleted!",
          message: "Scan is deleted successfully.",
          redirectTo,
          buttonTitle: "Ok",
        },
      });
      const filteredScans = getState().scanTable.filteredData;
      const nextItems = getState().scanTable.nextItems;
      const previousItems = getState().scanTable.previousItems;
      const scansCount = getState().scanTable.scansCount;
      const filteredBy = getState().scanTable.filteredBy;
      if (filteredScans.length > 0) {
        const updatedScansList = filter(
          filteredScans,
          (scan) => scan.scan_id !== scanInfo.scanId
        );

        dispatch({
          type: FILTER_SCAN,
          newFilteredData: updatedScansList,
          filteredBy,
        });
        dispatch({
          type: "SCANS_PAGE_LINKS",
          scansCount: scansCount - 1,
          nextItems,
          previousItems,
        });
      }
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const setScanToDelete = (scan) => (dispatch) => {
  dispatch({
    type: "SET_SCAN_TO_DELETE",
    scan,
  });
};

export const setScanToReRun = (scan) => (dispatch) => {
  dispatch({
    type: "SET_SCAN_TO_RE_RUN",
    scan,
  });
};

export const handleMarkScanAsFinished = (scanId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: "UPDATE_SCAN_REQUEST" });

    const requestBody = {
      status: "FINISHED",
      scan_id: scanId,
    };
    const response = await API.put("/scan/update/status/", requestBody);

    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: `Finished!`,
          message: `Scan is marked as finished successfully.`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteAllPendingErroredScans = () => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_ALL_PENDING_ERRORED_SCANS" });

    const response = await API.post("/scans/delete/", {});

    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message:
            "All Errored/Pending Scans of all organisations has been deleted successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, false);
  }
};

export const deleteOrgsPendingErroredScans = (orgId) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_ALL_PENDING_ERRORED_SCANS" });

    const response = await API.post("/scans/delete/", { org_id: orgId });

    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message:
            "All Errored/Pending Scans of this organisation has been deleted successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, false);
  }
};

export const fetchChildScans = (filterUrl, history, orgId) => async (
  dispatch
) => {
  try {
    dispatch({ type: FETCH_SCANS_REQUEST });

    const baseUrl = `/orgs/${orgId}/scans/`;
    const { data } = await API.get(`${baseUrl}${filterUrl}`);

    dispatch({
      type: FETCH_SCANS_SUCCESS,
      payload: data.results,
    });

    dispatch({
      type: "SCANS_PAGE_LINKS",
      scansCount: data.count,
      nextItems: data.next,
      previousItems: data.previous,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextChildScans = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SCANS_REQUEST });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: FETCH_SCANS_SUCCESS,
      payload: data.results,
    });

    dispatch({
      type: "SCANS_PAGE_LINKS",
      scansCount: data.count,
      nextItems: data.next,
      previousItems: data.previous,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchAeAggregate = (history, orgId, alert, search) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_AE_AGGREGATE_REQUEST" });
    const filter = search ? `&asset=${search}` : ''
    const baseUrl =
      alert && alert === "?alert=true"
        ? `/org/${orgId}/asset-exposure-agg/${alert}${filter}`
        : `/org/${orgId}/asset-exposure-agg/`;
    const { data } = await APIv2.get(`${baseUrl}`);

    dispatch({
      type: "FETCH_AE_AGGREGATE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchDlAggregate = (history, orgId, alert) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DL_AGGREGATE_REQUEST" });
    const baseUrl =
      alert && alert === "?alert=true"
        ? `/org/${orgId}/dataleak-inventory-agg/${alert}`
        : `/org/${orgId}/dataleak-inventory-agg/`;
    const { data } = await APIv2.get(`${baseUrl}`);

    dispatch({
      type: "FETCH_DL_AGGREGATE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchSrAggregate = (history, orgId, alert) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_SR_AGGREGATE_REQUEST" });

    const baseUrl =
      alert && alert === "?alert=true"
        ? `/org/${orgId}/vulnerability-inventory-agg/${alert} `
        : `/org/${orgId}/vulnerability-inventory-agg/ `;
    const { data } = await APIv2.get(`${baseUrl}`);

    dispatch({
      type: "FETCH_SR_AGGREGATE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchScansOverview = (history, orgId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_SCANS_OVERVIEW_REQUEST" });

    const baseUrl = `/org/${orgId}/scan/`;
    const { data } = await APIv2.get(`${baseUrl}`);
    localStorage.setItem("scanDetails", JSON.stringify(data));
    dispatch({
      type: "FETCH_SCANS_OVERVIEW_SUCCESS",
      payload: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchDeschedule = (orgId, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_SCANS_OVERVIEW_REQUEST" });

    const { data } = await API.post(`/orgs/disable-schedule/`, { org_id: orgId });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message:
          "Schedule removed successfully.",
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    const baseUrl = "/admin/orgs";
    await updateUrlAndFetchData(
      baseUrl,
      dispatch,
      fetchOrgsList,
      history
    );
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchUnvalidatedDomains = (history, orgId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_UNVALIDATED_DOMAINS_REQUEST" });

    const { data } = await API.get(`/orgs/${orgId}/req_domain/`);

    dispatch({
      type: "FETCH_UNVALIDATED_DOMAINS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteAssetConfirm = (ids, orgId, history, tab) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_SCAN_DATA_REQUEST" });
    const response = await API.post(`/org/${orgId}/asset-confirm-delete/`, {
      ids,
    });
    if (response.status === 200) {
      dispatch({ type: "DELETE_SCAN_DATA_SUCCESS" });
    }
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Delete",
        message: response.data.message,
        data: response.data.fields || [],
        buttonTitle: "Ok",
        showCancelButton: true,
        aiDalete: true,
      },
    });
    dispatch(showSuccessModal());
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteScanData = (
  ids,
  orgId,
  page,
  selected,
  title,
  tab,
  history
) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_SCAN_DATA_REQUEST" });
    const response = await API.delete(`/org/${orgId}/${page}/`, {
      data: {
        ids,
      },
    });
    if (response.status === 200) {
      dispatch({ type: "DELETE_SCAN_DATA_SUCCESS" });
    }
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Deleted",
        message:
          page === "asset-exposure"
            ? response.data.message
            : "Selected items deleted successfully",
        data: page === "asset-exposure" ? response.data.fields : [],
        aiDalete: false,
        showCancelButton: false,
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(resetSelectedIds());
    if (page === "issues") {
      dispatch(fetchRhSecurityRisksDetails(orgId, title, history));
      dispatch(fetchSrAggregate(history, orgId));
    } else if (page === "dataleaks") {
      dispatch(fetchRhDataleaksDetails(orgId, title, history));
      dispatch(fetchDlAggregate(history, orgId));
    } else if (page === "asset-exposure") {
      dispatch(fetchRhAssetExposureDetails(orgId, title, tab, history));
      dispatch(fetchAeAggregate(history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const scanDataActions = (action, orgId, page, type, tab) => async (
  dispatch,
  history
) => {
  try {
    dispatch({ type: "SCAN_VIEW_ACTION_REQUEST" });
    const response = await APIv2.put(`/orgs/${orgId}/${page}/`, action);
    if (response.status === 200) {
      dispatch({
        type: "SCAN_VIEW_ACTION_SUCCESS",
      });
    }
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success",
        message:
          action.action === "inactive"
            ? "Selected items inactivated successfully."
            : "Selected items updated successfully.",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(resetSelectedIds());
    if (page === "vulnerability-inventory" || action.type === "issue") {
      dispatch(fetchRhSecurityRisksDetails(orgId, type, history));
    } else if (page === "dataleak-inventory" || action.type === "dataleak") {
      dispatch(fetchRhDataleaksDetails(orgId, type, history));
    } else if (page === "asset-exposure" || action.type === "asset") {
      dispatch(fetchRhAssetExposureDetails(orgId, type, tab, history));
      dispatch(fetchAeAggregate(history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const scanDataStillActiveActions = (action, orgId, page, type, tab) => async (
  dispatch,
  history
) => {
  try {
    dispatch({ type: "SCAN_VIEW_ACTION_REQUEST" });
    const response = await API.put(`/org/${orgId}/${page}/`, action);
    if (response.status === 200) {
      dispatch({
        type: "SCAN_VIEW_ACTION_SUCCESS",
      });
    }
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success",
        message: "Selected Items Have Been Updated Successfully.",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(resetSelectedIds());
    if (page === "vulnerability-inventory" || action.type === "issue") {
      dispatch(fetchRhSecurityRisksDetails(orgId, type, history));
    } else if (page === "dataleak-inventory" || action.type === "dataleak") {
      dispatch(fetchRhDataleaksDetails(orgId, type, history));
    } else if (page === "asset-exposure" || action.type === "asset") {
      dispatch(fetchRhAssetExposureDetails(orgId, type, tab, history));
      dispatch(fetchAeAggregate(history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const validateDomain = (history, orgId, domain) => async (dispatch) => {
  try {
    dispatch({ type: "VALIDATE_DOMAIN_REQUEST" });

    // const requestBody = {
    //   domain,
    // };

    const { data } = await API.post(
      `/orgs/${orgId}/validate_domain/`,
      domain
    );

    dispatch({
      type: "VALIDATE_DOMAIN_SUCCESS",
      payload: data,
    });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Validation Successfull",
        message: "The domain has been validated successfully.",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(fetchReqDomain(history));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const validateAllDomain = (domain, history) => async (dispatch) => {
  try {
    dispatch({ type: "VALIDATE_DOMAIN_REQUEST" });
    const { data } = await API.post(
      `/bulk-verify-targets/`,
      domain
    );

    dispatch({
      type: "VALIDATE_DOMAIN_SUCCESS",
      payload: data,
    });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Validation Successfull",
        message: "The selected domains has been validated successfully.",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(fetchReqDomain(history));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleAwsValidation = (data, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_AWS_VALIDATION_REQUEST" });

    const { status } = await API.post("/orgs/assets/aws-creds/validate/", data);
    if (status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Validation Successfull",
          message: "The AWS keys has been validated successfully.",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch({ type: "FETCH_AWS_VALIDATION_SUCCESS" });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleCloudIntegrations = (data, history, type) => async (
  dispatch
) => {
  try {
    dispatch({ type: "HANDLE_CLOUD_INTEGRATION_REQUEST" });

    const response = await API.post("/orgs/cloud_keys/", data);
    if (response.status === 201) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title:
            type === "do"
              ? "Token Added"
              : type === "cloudFlare"
                ? "Key Added"
                : "Details Added",
          message: `${type === "do" ? "Token" : type === "cloudFlare" ? "Key" : "Details"
            } added successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(fetchTargets(history));
      dispatch(showSuccessModal());
      dispatch({ type: "HANDLE_CLOUD_INTEGRATION_SUCCESS" });
      type === "gcp"
        ? dispatch(gcpErrorData(""))
        : type === "cloudFlare"
          ? dispatch(cloudflareErrorData(""))
          : dispatch(doErrorData(""));
    }
  } catch (error) {
    if (error.response.status === 400) {
      type === "gcp"
        ? dispatch(gcpErrorData(error.response.data.error))
        : type === "cloudFlare"
          ? dispatch(cloudflareErrorData(error.response.data.error))
          : dispatch(doErrorData(error.response.data.error));
    }
    errorHandler(error, dispatch, history);
  }
};
export const handleUpdateCloudIntegrations = (data, history, type) => async (
  dispatch
) => {
  try {
    dispatch({ type: "HANDLE_CLOUD_INTEGRATION_REQUEST" });

    const response = await API.put("/orgs/cloud_keys/", data);
    if (response.status === 201 || 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title:
            type === "do"
              ? "Token Updated"
              : type === "cloudFlare"
                ? "Key Updated"
                : "Details Updated",
          message: `${type === "do" ? "Token" : type === "cloudFlare" ? "Key" : "Details"
            } Updated successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(fetchTargets(history));
      dispatch(hideIntegrationDetailsModal());
      dispatch(showSuccessModal());
      dispatch({ type: "HANDLE_CLOUD_INTEGRATION_SUCCESS" });
      type === "gcp"
        ? dispatch(gcpErrorData(""))
        : type === "cloudFlare"
          ? dispatch(cloudflareErrorData(""))
          : dispatch(doErrorData(""));
    }
  } catch (error) {
    if (error.response.status === 400) {
      type === "gcp"
        ? dispatch(gcpErrorData(error.response.data.error))
        : type === "cloudFlare"
          ? dispatch(cloudflareErrorData(error.response.data.error))
          : dispatch(doErrorData(error.response.data.error));
    }
    errorHandler(error, dispatch, history);
  }
};
export const handleDeleteCloudIntegrations = (history, type, id) => async (
  dispatch
) => {
  try {
    dispatch({ type: "HANDLE_CLOUD_INTEGRATION_REQUEST" });
    const target_id = id ? { target_id: id } : {}
    const response = await API.delete("/orgs/cloud_keys/", {
      data: { key: type, ...target_id },
    });
    if (response.status === 201 || 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Delete",
          message: `${type === "do" ? "Token" : type === "cloudflare" ? "Key" : "Details"
            } Deleted successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(fetchTargets(history));
      dispatch({ type: "HANDLE_CLOUD_INTEGRATION_SUCCESS" });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchRhDataleaksDetails = (orgId, title, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: "FETCH_RH_ORGS_DATALEAKS_REQUEST" });
    const params = title ? `?title=${title}` : "";
    const { data } = await APIv2.get(
      `/org/${orgId}/dataleak-inventory-agg/${params}`
    );

    dispatch({
      type: "FETCH_RH_ORGS_DATALEAKS_DETAILS_SUCCESS",
      dlDetails: data.summary,
      dlList: [
        ...data.instance.automated.map((i) => ({
          ...i,
          type: "automated",
        })),
        ...data.instance.manual.map((i) => ({
          ...i,
          type: "manual",
        })),
        ...data.instance.inactive.map((i) => ({
          ...i,
          type: "inactive",
        })),
      ],
      dlListAutomated: data.instance.automated.map((i) => ({
        ...i,
        type: "automated",
      })),
      dlListManual: data.instance.manual.map((i) => ({
        ...i,
        type: "manual",
      })),
      dlListInactive: data.instance.inactive.map((i) => ({
        ...i,
        type: "inactive",
      })),
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchRhAssetExposureDetails = (orgId, title, tab, history, search, order) => async (
  dispatch
) => {
  try {
    dispatch({ type: "FETCH_RH_ORGS_ASSET_EXPOSURE_REQUEST" });
    let asset = search ? `&asset=${search}` : ''
    const params = title ? `?title=${title}&status=${tab}${asset}` : "";
    const ordering = order ? params ? `&ordering=${order}${asset}` : `?ordering=${order}${asset}` : "";
    const { data } = await APIv2.get(
      `/org/${orgId}/asset-exposure-agg/${params}${ordering}`
    );

    dispatch({
      type: "FETCH_RH_ORGS_ASSET_EXPOSURE_DETAILS_SUCCESS",
      aeList: data.results ? data.results : [],
      nextItems: data.next,
      previousItems: data.previous,
      Count: data.count

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextAeTableData = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_RH_ORGS_ASSET_EXPOSURE_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'FETCH_RH_ORGS_ASSET_EXPOSURE_DETAILS_SUCCESS',
      aeList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      Count: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchPaymentPlans = (history) => async (dispatch) => {
  try {
    dispatch({ type: "PAYMENT_PLANS_REQUEST" });

    const { data } = await API_Payment.get("/product-list");

    dispatch({
      type: "PAYMENT_PLANS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({
      type: "PAYMENT_PLANS_SUCCESS",
      data: [{ 'name': 'scaleup', 'amount': '9000.00', 'mode': 'recurring', 'interval': 'year' }, { 'name': 'scaleup', 'amount': '1000.00', 'mode': 'recurring', 'interval': 'month' }, { 'name': 'startup', 'amount': '500.00', 'mode': 'recurring', 'interval': 'month' }, { 'name': 'startup', 'amount': '4800.00', 'mode': 'recurring', 'interval': 'year' }],
    });
    errorHandler(error, dispatch, history);
  }
};

export const getPaymentUrl = (
  history,
  items,
  mode,
) => async (dispatch) => {
  try {
    dispatch({ type: "GET_PAYMENT_URL" });

    const requestObject = {
      items,
      mode,
    };
    const response = await API_Payment.post(
      `/create-checkout-session/`,
      requestObject
    );

    if (response.status === 200) {
      dispatch({
        type: "GET_PAYMENT_URL_SUCCESS",
        data: response.data.url,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getModifyPaymentUrl = (history) => async (
  dispatch
) => {
  try {
    dispatch({ type: "GET_MODIFY_PAYMENT_URL" });


    const response = await API_Payment.post(
      `/create-customer-portal-session/`,
      {}
    );

    if (response.status === 200) {
      dispatch({
        type: "GET_MODIFY_PAYMENT_URL_SUCCESS",
        data: response.data.url,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const enterpriseForm = (params, history) => async (
  dispatch
) => {
  try {

    const response = await API_Payment.post(
      `/enterprise-form/`,
      params
    );

    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Delete",
          message: `Enterprise details submitted successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getSubscriptionStatus = (history) => async (
  dispatch
) => {
  try {
    dispatch({ type: "GET_MODIFY_PAYMENT_URL" });


    const response = await API_Payment.post(
      `/subscription-status/`,
      {}
    );

    if (response.status === 200) {
      dispatch({
        type: "GET_SUBSCRIPTION_STATUS_SUCCESS",
        data: response.data.status[0],
      });
      localStorage.setItem('subscriptionStatus', JSON.stringify(response.data.status[0]))
    }
  } catch (error) {
    dispatch({
      type: "GET_SUBSCRIPTION_STATUS_SUCCESS",
      data: { 'status': 'active', 'product': 'enterprise' },
    });
    localStorage.setItem('subscriptionStatus', "{ 'status': 'active', 'product': 'enterprise' }")
    errorHandler(error, dispatch, history);
  }
};

export const resetAwsValidationFailure = () => (dispatch) =>
  dispatch({ type: "FETCH_AWS_VALIDATION_RESET" });
export const resetSelectedIds = () => (dispatch) =>
  dispatch({ type: "FETCH_SELECTED_IDS_RESET" });
export const updateAeCount = (params) => (dispatch) =>
  dispatch({ type: "FETCH_AE_DETAILS_SUCCESS", aeDetails: params });


export const fetchRhSkipInventory = (filterUrl, history, orgId) => async (
  dispatch
) => {
  try {
    dispatch({ type: "FETCH_RH_ORGS_SKIP_INVENTORY_REQUEST" });

    const { data } = await API.get(
      `/org/${orgId}/skipped-vulnerability/${filterUrl}`
    );

    dispatch({
      type: "FETCH_RH_ORGS_SKIP_INVENTORY_SUCCESS",
      data: data.results ? data.results : [],
      nextItems: data.next,
      previousItems: data.previous,
      Count: data.count

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextRhSkipInventory = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: "FETCH_RH_ORGS_SKIP_INVENTORY_REQUEST" });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: "FETCH_RH_ORGS_SKIP_INVENTORY_SUCCESS",
      data: data.results ? data.results : [],
      nextItems: data.next,
      previousItems: data.previous,
      Count: data.count

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const markPositive = (orgId, id, params, history) => async (
  dispatch
) => {
  try {

    const response = await API.put(
      `/org/${orgId}/skipped-vulnerability/${id}`,
      params

    );

    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Mark false positive successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      const baseUrl = `/admin/orgs/${orgId}/scans/`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchRhSkipInventory,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const createVulnerability = (orgId, id, params, history) => async (
  dispatch
) => {
  try {

    const response = await API.post(
      `/org/${orgId}/skipped-vulnerability/${id}`,
      { vm_id: params }
    );

    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Vulnerability created successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(hideSkipInventoryModal());
      dispatch(showSuccessModal());
      const baseUrl = `/admin/orgs/${orgId}/scans/`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchRhSkipInventory,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getAllVulnerability = (history) => async (
  dispatch
) => {
  try {

    const { data } = await API.get(
      `/vulnerability-mapping/?page_size=1500`,
    );

    dispatch({
      type: "FETCH_RH_ORGS_VULNERABILITY_SUCCESS",
      data: data.results,

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchRhDataleakSkipInventory = (filterUrl, history, orgId) => async (
  dispatch
) => {
  try {
    dispatch({ type: "FETCH_RH_ORGS_SKIP_INVENTORY_REQUEST" });

    const { data } = await API.get(
      `/org/${orgId}/skipped-dataleak/${filterUrl}`
    );

    dispatch({
      type: "FETCH_RH_ORGS_SKIP_INVENTORY_SUCCESS",
      data: data.results ? data.results : [],
      nextItems: data.next,
      previousItems: data.previous,
      Count: data.count

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextRhDataleakSkipInventory = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: "FETCH_RH_ORGS_SKIP_INVENTORY_REQUEST" });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: "FETCH_RH_ORGS_SKIP_INVENTORY_SUCCESS",
      data: data.results ? data.results : [],
      nextItems: data.next,
      previousItems: data.previous,
      Count: data.count

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const dataleakMarkPositive = (orgId, id, params, history) => async (
  dispatch
) => {
  try {

    const response = await API.put(
      `/org/${orgId}/skipped-dataleak/${id}`,
      params

    );

    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Mark false positive successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      const baseUrl = `/admin/orgs/${orgId}/scans/`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchRhDataleakSkipInventory,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const createDataleak = (orgId, id, params, history) => async (
  dispatch
) => {
  try {

    const response = await API.post(
      `/org/${orgId}/skipped-dataleak/${id}`,
      { dsId: params }
    );

    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Dataleaks created successfully`,
          buttonTitle: "Ok",
        },
      });
      dispatch(hideSkipInventoryModal());
      dispatch(showSuccessModal());
      const baseUrl = `/admin/orgs/${orgId}/scans/`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchRhDataleakSkipInventory,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getAllDataleaks = (history) => async (
  dispatch
) => {
  try {

    const { data } = await API.get(
      `/dataleak-signature?page_size=1500`,
    );

    dispatch({
      type: "FETCH_RH_ORGS_DATALEAKS_SUCCESS",
      data: data.results,

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};